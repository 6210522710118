<template>
    <div id="web" style="width: 100%; height: 100%;">
        <iframe style="width: 100%; height: 100%;" :src="src"></iframe>
    </div>
</template>

<script>
export default {
    watch:{
        'path':function (newVal,oldVal){
            if(newVal){
                this.src="https://file-preview.wmenzai.com/translate_v2.php?path="+this.path
            }
        }
    },
    props: {
        path: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            src:'',
        };
    },
    mounted() {
        this.src="https://file-preview.wmenzai.com/translate_v2.php?path="+this.path
    },
    methods: {
    }
}
</script>

<style scoped>
iframe{
    overflow: auto;
    border: 0;
}
iframe::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
iframe::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
}
iframe::before {
    background-color: transparent;
}
/* 添加一些基本的样式 */
</style>
