import axios from 'axios';
import { Message } from 'element-ui';
import global from '../common/global'//引用模块进来
const ins = axios.create({
    headers: {
      'Content-Type':'application/json'
    },
    responseType: 'json',
})
// 请求拦截
ins.interceptors.request.use(config => {
    return config;
})

// 响应拦截
ins.interceptors.response.use(res => {
    return res.data
})

export default ins