<template>
  <div>
    <div class="box">
      <img class="page-img" src="../../static/no-jurisdiction.png" alt="">
      <div class="big-title">提示：{{title}}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title:''
    }
  },
  created() {
    window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
    this.title =this.$route.query.message
  },
  methods: {

  }
}
</script>

<style scoped>
.box {
    width: 1021px;
    margin: 170px auto 0;
}

.page-img {
    display: block;
    width: 1021px;
    height: 577px;
    margin: 170px auto 0;
}

.big-title {
    margin-top: -116px;
    color: #000;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
}

.back-login {
    margin-top: 30px;
    color: #000;
    font-size: 20px;
    text-align: center;
}

.login-page {
    color: #0089FF;
    cursor: pointer;
}

/* ---- 底部 ---- */
.footer {
    position: fixed;
    bottom: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    color: #8B8C8E;
    font-size: 12px;
    text-align: center;
}

.bottom-right a{
    margin-left: 89px;
    text-decoration: none;
}
</style>
